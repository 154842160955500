import Home from './Home';
import Products from './Products';
import AboutUs from './AboutUs';
import ImageGallery from './ImageGallery';
import Contact from './Contact';

export default function MainSections() {
	return (
		<main>
			<Home />
			<AboutUs />
			<Products />
			<ImageGallery />
			<Contact />
		</main>
	);
}
