import styled from 'styled-components';
import { device } from '../device';

const GridContainer = styled.div`
	display: inherit;
	flex-direction: inherit;
	justify-items: inherit;
	align-items: inherit;
	margin: auto;

	max-width: 100vw;

	@media ${device.tablet} {
		max-width: 90vw;
	}
	@media ${device.laptop} {
		max-width: 80vw;
	}
`;

export default function Grid({ children }) {
	return <GridContainer>{children}</GridContainer>;
}
