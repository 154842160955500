import styled from 'styled-components';
import { device } from '../device';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';
import { A11y, Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './css/swiper-override.scss';

import Monaco2 from '../img/monaco-2-opt.jpg';
import Child2 from '../img/child-2-opt.jpg';
import BabyBox from '../img/mali-baby-opt.jpg';
import Monaco from '../img/malibabi-monaco-opt.jpg';
import Bob9 from '../img/bob-9-version-2.jpg';
import CloseUp from '../img/close-up-light.jpg';

const ImageGalleryContainer = styled.section`
	display: flex;
	flex-wrap: wrap;
	max-width: 1200px;
	margin: auto;
	overflow: hidden;

	@media ${device.tablet} {
		padding: 0 4px;
	}
	@media ${device.laptop} {
		margin-bottom: 40px;
	}
`;

const SwiperContainer = styled.div`
	width: 100%;
`;

const Title = styled.h1`
	flex: 100%;
	text-align: center;
	margin: 40px 0;
	font-size: 2em;

	@media ${device.laptop} {
		font-size: 2.2em;
	}
	@media ${device.laptopL} {
		font-size: 3em;
	}
`;

const Image = styled.div`
	background-image: url(${props => props.imgSrc});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 400px;

	@media ${device.tablet} {
		height: 600px;
	}
	@media ${device.laptop} {
		height: 700px;
		background-size: contain;
	}
`;

export default function ImageGallery() {
	return (
		<ImageGalleryContainer id="image-gallery">
			<Title>Gallery</Title>
			<SwiperContainer>
				<Swiper
					modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
					onSwiper={swiper => (window.swiper = swiper)}
					slidesPerView={1}
					spaceBetween={0}
					navigation
					loop
					autoplay={{ delay: 2500 }}
					pagination={{ clickable: true }}
				>
					<SwiperSlide>
						<Image imgSrc={Monaco2} alt="Malibabi sign with boat in background" />
					</SwiperSlide>
					<SwiperSlide>
						<Image imgSrc={Child2} alt="Children holding malibabi sign" />
					</SwiperSlide>
					<SwiperSlide>
						<Image imgSrc={BabyBox} alt="Malibabi sign in a box" />
					</SwiperSlide>
					<SwiperSlide>
						<Image imgSrc={Monaco} alt="Malibabi sign in Monaco" />
					</SwiperSlide>
					<SwiperSlide>
						<Image imgSrc={Bob9} alt="Malibabi sign in car at night" />
					</SwiperSlide>
					<SwiperSlide>
						<Image imgSrc={CloseUp} alt="Close up of the Malibabi sign" />
					</SwiperSlide>
				</Swiper>
			</SwiperContainer>
		</ImageGalleryContainer>
	);
}
