import { useRef } from 'react';
import styled from 'styled-components';
import { device } from '../device';
import Navbar from './Navbar';
import Grid from './Grid';

import LogoImg from '../img/logo-crop.png';

// Styled components
const HeaderContainer = styled.header`
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	border-radius: 0 0 20% 20%;
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100vw;
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	@media ${device.laptopL} {
		flex-direction: row;
		border-radius: 0 0 30% 30%;
	}
`;

const Logo = styled.div`
	background-image: url(${LogoImg});
	width: min(80vw, 300px);
	height: 80px;
	margin-top: 5px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	@media ${device.tablet} {
		margin: 0 20px;
	}
`;

export default function Header() {
	// change header section to a navbar on scroll
	const headerRef = useRef(null);

	return (
		<HeaderContainer id="header" ref={headerRef}>
			<Grid>
				<Logo className="logo" />
				<Navbar headerRef={headerRef} />
			</Grid>
		</HeaderContainer>
	);
}
