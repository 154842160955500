import { useRef } from 'react';
import styled from 'styled-components';
import HomeVideo from '../img/video-loop-opt.mp4';

const HomeContainer = styled.section`
	width: 100vw;
	height: 100vh;
`;

const Mp4 = styled.video`
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	object-position: 67.5%;
`;

export default function AboutUs() {
	const videoRef = useRef(null);

	return (
		<HomeContainer id="home">
			<Mp4 ref={videoRef} autoPlay muted loop playsInline>
				<source type="video/mp4" src={HomeVideo} />
				Sorry, your browser doesn't support embedded videos.
			</Mp4>
		</HomeContainer>
	);
}
