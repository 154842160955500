import styled from 'styled-components';
import { size, device } from '../device';

import MalibabiReverse from '../img/malibabi-reverse-opt.png';
import Bob1 from '../img/bob-1-opt.jpg';

const ProductsSection = styled.section`
	width: 100%;
	background: #def2f1;
`;

const ProductsContainer = styled.div`
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto;
	grid-template-areas:
		'title'
		'text1'
		'image1'
		'text2'
		'text3'
		'image2'
		'text4';
	margin: auto;

	@media ${device.laptop} {
		grid-template-columns: 50% 50%;
		grid-template-areas:
			'title title'
			'image1 text1'
			'image1 text2'
			'text3 image2'
			'text4 text4';
		align-items: center;
		padding: 0 5%;
		max-width: ${size.laptop};
	}
	@media ${device.laptopL} {
		max-width: ${size.laptopL};
	}
`;

const Title = styled.h1`
	grid-area: title;
	justify-self: center;
	margin: 40px 0 0;

	@media ${device.laptop} {
		font-size: 2.2em;
		margin: 40px 0 20px;
	}
	@media ${device.laptopL} {
		font-size: 3em;
	}
`;

const Paragraph = styled.p`
	grid-area: ${props => props.gridArea};
	z-index: 2;
	padding: 0 5%;

	@media ${device.laptop} {
		padding: 0;
		font-size: 1.2em;
		${props => props.largeWidth && `width: ${props.largeWidth}`};
		${props => props.largePadding && `padding: ${props.largePadding}`};
		${props => props.largeTextAlign && `text-align: ${props.largeTextAlign}`};
		${props => props.largeAlignSelf && `align-self: ${props.largeAlignSelf}`};
	}
	@media ${device.laptopL} {
		font-size: 1.4em;
	}
`;

const Image = styled.img`
	grid-area: ${props => props.gridArea};
	width: 100vw;

	${props =>
		props.bob &&
		`
      @media ${device.laptop} {
        width: 100%;
        border-radius: 50px;
      }
      @media ${device.laptopL} {
        max-height: 537px;
      }
		`}

	${props =>
		props.sign &&
		`
      justify-self: center;
			width: 60%;
      padding: 10px;
      background: white;
      border-radius: 47.5%;
      max-width: 250px;
      max-height: 250px;

      @media ${device.tablet} {
        width: 40%;
      }
		`}
`;

export default function Products() {
	return (
		<ProductsSection id="products">
			<ProductsContainer>
				<Title>Our products</Title>
				<Paragraph gridArea="text1" largePadding="0 0 0 20px" largeAlignSelf="end">
					Malibabi uses Photoluminescent technology to illuminate the sign in low light conditions
					and also through tinted car windows. The signs are also much more visible in daylight
					conditions compared to other signs.
				</Paragraph>
				<Image src={Bob1} alt="Malibabi sign glowing at night" gridArea="image1" bob />
				<Paragraph gridArea="text2" largePadding="0 0 0 20px" largeAlignSelf="start">
					Our version of this classic sign brings it back to basics and makes the message clearer:
					to create maximum awareness for other road users that you have a baby/child on board to
					encourage safer driving around your vehicle.
				</Paragraph>
				<Paragraph gridArea="text3" largeWidth="120%" largeTextAlign="right">
					Around 46% of car users with a Child or Baby on board sign don't remove them when their
					child isn't in the vehicle so our signs have also been designed to have a unique safety
					message on the back for the driver, which can be read in the rear-view mirror to remind
					you to remove the sign when there is no baby or child in the vehicle.
				</Paragraph>
				<Image
					src={MalibabiReverse}
					alt="Malibabi sign reversed to show the safety message"
					gridArea="image2"
					sign
				/>
				<Paragraph gridArea="text4" largeTextAlign="center">
					We know as parents ourselves that your child's safety is the most important thing in the
					world, so we designed a product to better meet these needs on the road.
				</Paragraph>
			</ProductsContainer>
		</ProductsSection>
	);
}
