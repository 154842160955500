import styled from 'styled-components';

import LogoIcon from '../img/logo-icon.png';

const FooterContainer = styled.footer`
	display: grid;
	grid-template-columns: 5% 30% 30% 30% 5%;
	grid-template-rows: 64px;
	grid-template-areas: 'left logo text top right';
	align-items: center;
	justify-items: center;
	width: 100vw;
	background-color: #c0e2ce;
	padding: 30px 0;
`;

const Logo = styled.img`
	grid-area: logo;
	width: 64px;
	height: 64px;
	border-radius: 100%;
`;

const Text = styled.div`
	grid-area: text;
	text-align: center;
`;

const BackToTop = styled.div`
	grid-area: top;
	display: grid;
	justify-items: center;
	align-items: center;
	width: 64px;
	height: 64px;
	background-color: #fff;
	border-radius: 100%;
	cursor: pointer;
`;
const Arrow = styled.span`
	display: inline-block;
	width: 15px;
	height: 15px;
	border-top: 3px solid #000;
	border-right: 3px solid #000;
	margin-top: 6px;
	transform: rotate(-45deg);
`;

export default function Footer() {
	const today = new Date();

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<FooterContainer>
			<Logo src={LogoIcon} alt="Malibabi logo without text" />
			<Text>Malibabi &copy; {today.getFullYear()}</Text>
			<BackToTop onClick={scrollToTop}>
				<Arrow />
			</BackToTop>
		</FooterContainer>
	);
}
