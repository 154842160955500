import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { Squash as Hamburger } from 'hamburger-react';
import { device } from '../device';

// Styled components
const Nav = styled.nav`
	font-size: 1.2em;
	width: 100%;
`;

const NavList = styled.ul`
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: center;

	@media ${device.tablet} {
		display: flex;
		align-items: center;
	}
	@media ${device.laptopL} {
		justify-content: flex-end;
	}
`;

const NavLink = styled.li`
	display: flex;
	justify-content: center;
	padding: 3px 0;
	cursor: pointer;

	.highlight {
		padding: 8px;
		line-height: 100%;
	}
	&.active .highlight {
		background-color: #c0e2ce;
		border-radius: 10px;
	}
	:last-of-type {
		padding-bottom: 6px;
	}

	@media ${device.tablet} {
		padding: 0 2px 6px 2px;
	}
`;

const Dot = styled.span`
	height: 4px;
	width: 4px;
	margin: 0 2px 3px;
	background-color: #000;
	border-radius: 50%;
`;

export default function Navbar({ headerRef }) {
	const navLinks = [
		{ text: 'Home', target: 'home' },
		{ text: 'About us', target: 'about-us' },
		{ text: 'Products', target: 'products' },
		{ text: 'Gallery', target: 'image-gallery' },
		{ text: 'Contact', target: 'contact' },
	];

	const [isOpen, setOpen] = useState(false);
	const [mobileView, setMobileView] = useState(false);
	const navRef = useRef(null);

	// check if screen size is mobile on load/resize
	useEffect(() => {
		const checkMobile = () => {
			const media = window.matchMedia('(max-width: 767px)');
			setMobileView(media.matches);
		};

		checkMobile();
		window.addEventListener('resize', checkMobile);
	});

	// nav links scroll
	const handleNavigate = e => {
		const targetID = e.currentTarget.getAttribute('data-target-id');
		const target_element = document.getElementById(targetID);
		const navLinksHeight = navRef.current.offsetHeight;
		const extraSpacing = mobileView ? 25 : -15;
		const topY = target_element.offsetTop + navLinksHeight + extraSpacing;
		const navHeight = document.getElementById('header').offsetHeight;

		window.scrollTo({ top: topY - navHeight, behavior: 'smooth' });
	};

	// nav links highlight on scroll
	useEffect(() => {
		const handleActiveLink = () => {
			const section_elements = [...document.getElementsByTagName('section')];
			const y = window.scrollY + headerRef.current.offsetHeight;

			section_elements.forEach(section => {
				const secTop = section.offsetTop;
				const secBot = section.offsetTop + section.offsetHeight;
				const secLink = document.querySelector(`[data-target-id=${section.id}]`);
				if (secLink) secLink.classList.remove('active');

				if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
					const lastSecLink = document.querySelector(
						`[data-target-id=${section_elements[section_elements.length - 1].id}]`
					);
					if (lastSecLink) lastSecLink.classList.add('active');
				} else if (secLink) {
					if (y >= secTop && y < secBot) {
						secLink.classList.add('active');
					}
				}
			});
		};

		handleActiveLink();
		window.addEventListener('scroll', handleActiveLink);
	});

	return (
		<>
			{mobileView && <Hamburger toggled={isOpen} toggle={setOpen} size={28} label="Show menu" />}
			{(!mobileView || (isOpen && mobileView)) && (
				<Nav ref={navRef}>
					<NavList>
						{navLinks.map((link, i) => (
							<React.Fragment key={uuidv4()}>
								<NavLink data-target-id={link.target} onClick={e => handleNavigate(e)}>
									<span className="highlight">{link.text}</span>
								</NavLink>
								{!mobileView && i < navLinks.length - 1 && <Dot key={uuidv4()} />}
							</React.Fragment>
						))}
					</NavList>
				</Nav>
			)}
		</>
	);
}
