import styled from 'styled-components';
import { size, device } from '../device';

import AboutImage from '../img/contact-optimised.jpg';

const AboutUsContainer = styled.section`
	display: grid;
	grid-template-rows: auto;
	grid-template-areas:
		'title'
		'paragraphs'
		'image';
	padding-top: 25px;
	margin: auto;

	@media ${device.tablet} {
		grid-template-columns: 50% 50%;
		grid-template-areas:
			'title title'
			'paragraphs image';
		margin-bottom: 25px;
	}
	@media ${device.laptop} {
		grid-template-areas:
			'title image'
			'paragraphs image';
		max-width: ${size.laptop};
	}
	@media ${device.laptopL} {
		max-width: ${size.laptopL};
	}
`;

const Title = styled.h1`
	grid-area: title;
	text-align: center;
	margin: 10px 0;

	@media ${device.laptop} {
		margin-left: 5%;
		text-align: left;
		font-size: 2.2em;
	}
	@media ${device.laptopL} {
		font-size: 3em;
	}
`;

const TextContainer = styled.div`
	grid-area: paragraphs;
`;

const Paragraph = styled.p`
	width: 90vw;
	margin: 10px auto;
	text-align: justify;

	@media ${device.tablet} {
		width: 90%;
	}
	@media ${device.laptop} {
		text-align: left;
	}
	@media ${device.laptopL} {
		font-size: 1.4em;
	}
`;

const Image = styled.img`
	grid-area: image;
	width: 100%;

	@media ${device.tablet} {
		align-self: center;
	}
`;

export default function AboutUs() {
	return (
		<AboutUsContainer id="about-us">
			<Title>About us</Title>
			<TextContainer>
				<Paragraph>
					Malibabi was founded in 2017 and jointly named after our daughter <b>Mali</b> and the
					Welsh word for baby: <b>babi</b>.
				</Paragraph>
				<Paragraph>
					Here at <b>Malibabi</b> we are committed to designing and supplying visual aids and other
					baby/child safety products to improve safety and save lives.
				</Paragraph>
				<Paragraph>
					Our products are proudly designed and made in Wales. Our daughter <b>Mali</b> isn't just
					the inspiration behind our products and our name, she has also designed our logo, so its a
					real family business.
				</Paragraph>
				<Paragraph>
					Our first product is a clean modern take on the classic <em>baby on board</em> and
					<em> child on board vehicle safety signs</em>, but with a difference.
				</Paragraph>
			</TextContainer>
			<Image src={AboutImage} alt="Malibabi being designed by Mali, black and white" />
		</AboutUsContainer>
	);
}
