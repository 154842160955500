import styled from 'styled-components';
import { device } from '../device';

import Facebook from '../img/facebook-black.png';
import FacebookActive from '../img/facebook-active.png';
import FacebookHover from '../img/facebook-hover.png';
import Twitter from '../img/twitter-black.png';
import TwitterActive from '../img/twitter-active.png';
import TwitterHover from '../img/twitter-hover.png';
import Instagram from '../img/instagram-black.png';
import InstagramActive from '../img/instagram-active.png';
import InstagramHover from '../img/instagram-hover.png';
import ContactImage from '../img/mali-designing-opt.jpg';

const ContactContainer = styled.section`
	display: grid;
	grid-template-columns: 5% 40% 50% 5%;
	grid-template-rows: repeat(4, auto);
	grid-template-areas:
		'title title title title'
		'left email email right'
		'left social social right'
		'image image image image';
	row-gap: 20px;
	padding-top: 25px;
	font-size: 1.4em;

	@media ${device.laptop} {
		gap: 10px 50px;
		padding-bottom: 15px;
	}
`;

const Title = styled.h1`
	grid-area: title;
	text-align: center;
	margin: 10px 0;

	@media ${device.laptop} {
		grid-area: 1 / 3;
		justify-self: start;
		font-size: 2.2em;
	}
	@media ${device.laptopL} {
		font-size: 3em;
	}
`;
const Email = styled.a`
	grid-area: email;
	justify-self: center;
	text-decoration: none;
	text-align: center;
	color: #000;
	font-weight: bold;
	border: 4px solid #c0e2ce;
	border-radius: 10px;
	padding: 10px;
	cursor: pointer;

	:hover {
		text-decoration: underline;
	}
	:active {
		border: 4px solid #7edf2d;
	}

	@media ${device.laptop} {
		grid-area: 2 / 3;
		justify-self: start;
		height: 30px;
	}
`;

const SocialContainer = styled.div`
	grid-area: social;
	display: flex;
	justify-content: center;
	gap: 25px;

	@media ${device.laptop} {
		grid-area: 3 / 3;
		justify-self: start;
	}
`;
const SocialLink = styled.div`
	width: 64px;
	height: 64px;
	cursor: pointer;
	background-image: url(${props => props.imgSrc});
	background-repeat: no-repeat;
	background-size: contain;

	:hover {
		background-image: url(${props => props.imgSrcHover || props.imgSrc});
	}
	:active {
		background-image: url(${props => props.imgSrcActive || props.imgSrc});
	}
`;

const Image = styled.img`
	grid-area: image;
	width: 100vw;

	@media ${device.tablet} {
		object-fit: cover;
		height: 70vh;
	}
	@media ${device.laptop} {
		justify-self: end;
		width: min(100%, 650px);
		height: auto;
		max-height: 400px;
		grid-area: 1 / 2 / 4;
	}
`;

export default function Contact() {
	return (
		<ContactContainer id="contact">
			<Title>Contact us</Title>
			<Email
				href="mailto:info@malibabi.com?subject=Malibabi enquiry
        &body=Hello, %0D%0A
        I'd like to ask about Malibabi products"
			>
				info@malibabi.com
			</Email>
			<SocialContainer>
				<a href="https://www.facebook.com/malibabiuk/" target="_blank" rel="noreferrer">
					<SocialLink
						imgSrc={Facebook}
						imgSrcHover={FacebookHover}
						imgSrcActive={FacebookActive}
						role="img"
						alt="Facebook"
						aria-label="Facebook"
					/>
				</a>
				<a href="https://twitter.com/malibabiuk" target="_blank" rel="noreferrer">
					<SocialLink
						imgSrc={Twitter}
						imgSrcHover={TwitterHover}
						imgSrcActive={TwitterActive}
						role="img"
						alt="Twitter"
						aria-label="Twitter"
					/>
				</a>
				<a href="https://www.instagram.com/malibabiuk" target="_blank" rel="noreferrer">
					<SocialLink
						imgSrc={Instagram}
						imgSrcHover={InstagramHover}
						imgSrcActive={InstagramActive}
						role="img"
						alt="Instagram"
						aria-label="Instagram"
					/>
				</a>
			</SocialContainer>
			<Image src={ContactImage} alt="Malibabi being designed by Mali" />
		</ContactContainer>
	);
}
