import styled from 'styled-components';

import MainSections from './components/MainSections';
import Header from './components/Header';
import Footer from './components/Footer';

const AppContainer = styled.div`
  scroll-behavior: smooth;
`;

export default function App() {

  return (
    <AppContainer>
      <Header></Header>
      <MainSections></MainSections>
      <Footer></Footer>
    </AppContainer>
  );
}
